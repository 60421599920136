<template>
  <div>
    <main-header />
    <div class="account--section">
      <div class="container log">
        <div>
          <div class="px-3">
            <div class="card card--account">
              <div class="card-body tab-content">
                <br />
                <div class="_flex _between">
                  <h5 class="text-uppercase font-weight-bold my-0">Mon Compte</h5>
                  <template>
                    <button class="btn btn-log" @click="editing = !editing" v-if="!editing">
                      Modifier
                    </button>
                    <button class="btn btn-log" @click="onSubmit" v-if="editing">
                      <i class="far fa-spinner fa-pulse mr-2" v-if="loading"></i> Mettre à jour
                    </button>
                  </template>
                </div>
                <hr />
                <br />
                <fieldset class="text-center" :disabled="!editing">
                  <section class="text-uppercase text-bold">
                    <div v-if="!fullname.trim().length">
                      Mettre à jour votre profil
                    </div>
                    <div v-else>Bienvenu(e)<span v-if="fullname.trim()">, {{ fullname }}</span></div>
                  </section>
                  <br />
                  <div class="user-registration-profile-header">
                    <div class="user-avatar-large">
                      <img v-if="pictureUrl" :src="pictureUrl" alt="" style="object-fit: fill"/>
                    </div>
                    <p class="user-registration-tips">Taille maximale: 2 Mo</p>
                    <small><strong>Téléchargez votre nouvelle image de profil.</strong></small>
                    <div class="d-flex justify-content-center">
                      <button class="btn btn-log" style="color:#222;font-size:12px" @click="onPickPicture">Choisir image</button>
                      <input type="file" style="display: none;" ref="fileInput" accept="image/*" @change="onPicturePicked"/>
                    </div>
                    <!--  -->
                    <div style="margin: 20px -10px 0;text-align: left;">
                      <form>
                        <div class="form-group">
                          <label for="updateFirstname">Nom</label>
                          <input v-model.trim="dataForm.first_name" id="updateFirstname" type="text" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label for="updateMiddleName">Post-nom</label>
                          <input v-model.trim="dataForm.middle_name" id="updateMiddleName" type="text" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label for="updateLastName">Prénom</label>
                          <input v-model.trim="dataForm.last_name" id="updateLastName" type="text" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label for="updateEmail">Email</label>
                          <input v-model.trim="dataForm.email" type="email" id="updateEmail" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label for="updatePhone">Téléphone</label>
                          <input v-model.trim="dataForm.phone" type="tel" id="updatePhone" class="form-control"/>
                        </div>
                        <div v-if="getCountries && getCountries.length > 0" class="form-group">
                          <label>Pays</label>
                          <select v-model="dataForm.country" class="form-control">
                            <option :value="country.name_fr" v-for="country in getCountries" :key="country.id">
                              {{ country.name_fr.charAt(0).toUpperCase() + country.name_fr.substring(1).toLowerCase() }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="updateCity">Ville</label>
                          <input v-model.trim="dataForm.city" type="text" id="updateCity" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label for="updateAddress" class="text-normal">Adresse</label>
                          <input id="updateAddress" type="text" class="form-control" />
                        </div>
                        <div class="form-group">
                          <label class="text-normal">Nouveau mot de passe</label>
                          <input v-model.trim="dataForm.new_password" type="password" class="form-control" />
                        </div>
                        <div class="form-group">
                          <label class="text-normal">Confirmer nouveau mot de passe</label>
                          <input type="password" class="form-control" />
                        </div>
                        <div class="text-right">
                          <button class="btn btn-log" @click="onSubmit" :disabled="loading || !editing">
                            <i class="far fa-spinner fa-pulse mr-2" v-if="loading"></i>Sauvegarder
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data: () => ({
    dataForm: {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      avatar: "",
      password: "",
      new_password: "",
      type_user: "",
      status: "",
      address: "",
      table: "mise_a_jour",
      table_file: "addPicture",
      table_id: ""
    },
    pictureUrl: "",
    picture: null,
    editing: true,
    loading: false,
  }),
  methods: {
    ...mapMutations([]),
    ...mapActions([
      "tryAutoLogin",
      "axiosPutData",
      "axiosPostFileData",
    ]),
    onPickPicture() {
      this.$refs.fileInput.click();
    },
    onPicturePicked(e) {
      let pictureUploaded = e.target.files[0]
      if(pictureUploaded){
        let sizePictureMb = (pictureUploaded.size/1000000).toFixed(2)
        // let sizePictureKb = (pictureUploaded.size/1000).toFixed(2)
        if(sizePictureMb > 2){
          this.$toast.warning("Veuillez svp choisir une image dont la taille ne dépasse pas 2Mb !", {
            icon: "fas fa-2x fa-exclamation",
          });
        }else {
          this.pictureUrl = URL.createObjectURL(pictureUploaded);
          this.picture = pictureUploaded;
        }
      }
    },
    onUploadPicture(){
      if(this.picture){
        let formData = new FormData()
        formData.append('file_uploaded', this.picture)
        formData.append('user_id', this.getToken)
        formData.append('category_file', 'users')
        this.axiosPostFileData({data: formData, dataForm: this.dataForm})
            .then(response => {
              if(response.status === 200){
                this.tryAutoLogin()
                this.$toast.success(response.data.message, {
                  icon: "fas fa-2x fa-check-circle",
                });
              }else {
                this.$toast.error(response.data.message, {
                  icon: "fas fa-2x fa-times-circle",
                });
              }
            })
            .catch(error => {
              this.loading = false
            })
      }
    },
    onSubmit(){
      this.loading = true
      this.resetDefaultValues()
      this.axiosPutData(this.dataForm)
          .then( response => {
            if(response.status === 200){
              this.tryAutoLogin()
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
              this.onUploadPicture()
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.loading = false;
          })
          .catch()
    },
    resetDefaultValues() {
      if(this.dataForm.first_name === '')
        this.dataForm.first_name = this.getUserData.userData.first_name
      if(this.dataForm.middle_name === '')
        this.dataForm.middle_name = this.getUserData.userData.middle_name
      if(this.dataForm.last_name === '')
        this.dataForm.last_name = this.getUserData.userData.last_name
      if(this.dataForm.email === '')
        this.dataForm.email = this.getUserData.userData.email
      if(this.dataForm.phone === '')
        this.dataForm.phone = this.getUserData.userData.phone
      if(this.dataForm.country === '')
        this.dataForm.country = this.getUserData.userData.country
      if(this.dataForm.city === '')
        this.dataForm.city = this.getUserData.userData.city
      if(this.dataForm.avatar === '')
        this.dataForm.avatar = this.getUserData.userData.avatar
      if(this.dataForm.new_password !== '')
        this.dataForm.password = this.dataForm.new_password
      if(this.dataForm.type_user === '')
        this.dataForm.type_user = this.getUserData.userData.type_user
      if(this.dataForm.status === '')
        this.dataForm.status = this.getUserData.userData.status
      if(this.dataForm.address === '')
        this.dataForm.address = this.getUserData.userData.address
    },
    setDefaultValues(data) {
      this.dataForm.first_name = data.first_name
      this.dataForm.middle_name = data.middle_name
      this.dataForm.last_name = data.last_name
      this.dataForm.email = data.email
      this.dataForm.phone = data.phone
      this.dataForm.country = data.country
      this.dataForm.city = data.city
      this.dataForm.avatar = data.avatar
      this.dataForm.type_user = data.type_user
      this.dataForm.status = data.status
      this.dataForm.address = data.address
    }
  },
  computed: {
    ...mapGetters([
      "getUserData",
      "getToken",
      "getCountries"
    ]),
    fullname() {
      if (!this.getUserData) return "";
      return `${this.getUserData.userData.last_name || ""} ${this.getUserData.userData.middle_name || ""} ${this.getUserData.userData.first_name || ""}`;
    },
  },
  watch: {
    getToken(value) {
      if (!value) {
        return this.$router.push("/");
      }
    },
    getUserData(value) {
      if (value && value.userData) {
        this.setDefaultValues(value.userData)
      }
    },
  },
  beforeCreate() {},
  created() {
    this.tryAutoLogin()
    this.dataForm.table_id = this.getToken
    this.pictureUrl = this.getUserData.userData.picture_data.src
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="scss" scoped>
@import url(../../assets/scss/account.scss);

.form-group label {
  font-size: 12px;
  text-transform: uppercase;
}
</style>
